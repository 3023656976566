import { CommonState, Fetcher, FetchHelper } from '@primeit/components-vue';
const fetchHelper = new FetchHelper();
const module = {
  actions: {
    FETCH_ALL_CUSTOM_MESSAGES: async (context, params) => {
      if (!params) {
        params = {};
      }
      const response = await fetchHelper.fetchAll(
        `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.id}/custommessage`,
        params,
        true
      );
      context.commit('POPULATE_ALL_CUSTOM_MESSAGES', response);
    },
    FETCH_EDIT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/myprofile`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT', json);
    },
    FETCH_EDIT_DATA: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/myprofile/data`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT_DATA', json);
    },
    FETCH_EDIT_LANDING: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/myprofile/landing`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT_LANDING', json);
    },
    FETCH_EDIT_PICTURES: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/myprofile/pictures`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT_PICTURES', json);
    },
    FETCH_EDIT_THEME: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/myprofile/theme`
        );
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EDIT_THEME', json);
    },
    FETCH_SAVE_SHORTCUT: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/settings`, {
          body: JSON.stringify({ shortcuts: params.shortcuts }),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      json.entity = 'shortcuts';
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_SAVE_ROOM: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.companyId}/settings`, {
          body: JSON.stringify({ room: params.room }),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      json.entity = 'room';
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_UPDATE: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.id}/myprofile`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_UPDATE_CUSTOM_MESSAGES: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.id}/custommessage`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_UPDATE_DATA: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.id}/myprofile/data`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      json.entity = 'data';
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_UPDATE_LANDING: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.id}/myprofile/landing`,
          {
            body: JSON.stringify(params),
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            method: 'PUT',
          }
        );
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      json.entity = 'landing';
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_UPDATE_PICTURES: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.id}/myprofile/pictures`,
          {
            body: JSON.stringify(params),
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            method: 'PUT',
          }
        );
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      json.entity = 'pictures';
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    FETCH_UPDATE_THEME: async (context, params) => {
      let response;
      let json;

      try {
        response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_COMPANY}/${params.id}/myprofile/theme`, {
          body: JSON.stringify(params),
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          method: 'PUT',
        });
        json = await response.json();
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      json.entity = 'theme';
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
  },
  getters: {
    GET_ALL_CUSTOM_MESSAGES: (state) => {
      return JSON.parse(JSON.stringify(state.customMessages));
    },
    GET_EDIT: (state) => {
      return JSON.parse(JSON.stringify(state.edit));
    },
    GET_EDIT_DATA: (state) => {
      return JSON.parse(JSON.stringify(state.data));
    },
    GET_EDIT_LANDING: (state) => {
      return JSON.parse(JSON.stringify(state.landing));
    },
    GET_EDIT_PICTURES: (state) => {
      return JSON.parse(JSON.stringify(state.pictures));
    },
    GET_EDIT_THEME: (state) => {
      return JSON.parse(JSON.stringify(state.theme));
    },
  },
  mutations: {
    POPULATE_ALL_CUSTOM_MESSAGES: (state, payload) => {
      if (payload) {
        state.customMessages = payload;
      }
    },
    POPULATE_EDIT: (state, payload) => {
      if (payload) {
        state.edit = payload;
      }
    },
    POPULATE_EDIT_DATA: (state, payload) => {
      if (payload) {
        state.data = payload;
      }
    },
    POPULATE_EDIT_LANDING: (state, payload) => {
      if (payload) {
        state.landing = payload;
      }
    },
    POPULATE_EDIT_PICTURES: (state, payload) => {
      if (payload) {
        state.pictures = payload;
      }
    },
    POPULATE_EDIT_THEME: (state, payload) => {
      if (payload) {
        state.theme = payload;
      }
    },
  },
  namespaced: true,
  state: {
    customMessages: null,
    data: null,
    edit: null,
    landing: null,
    pictures: null,
    theme: null,
  },
};

CommonState.registerModule('company', module);
